import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Paper from "../../global/Paper"
import styles from "./Header.module.css"
import { FaBars } from "react-icons/fa"
import { Hidden, Drawer, Divider } from "@material-ui/core"
import * as SnipcartStyles from "./SnipcartOverride.module.css"
import ListLink from "./ListLink"
//icons
import {
  MdShoppingBasket,
  MdInfo,
  MdEmail,
  MdShoppingCart,
} from "react-icons/md"
import { FaFacebookSquare, FaTwitter, FaInstagram } from "react-icons/fa"

export default function Header() {
  const [open, setDrawer] = useState(false)
  const [hideHeader, setHideHeader] = useState(false)

  const location = useLocation()

  useEffect(() => {
    if (location.hash.length) {
      setHideHeader(true)
    } else {
      setHideHeader(false)
    }
  }, [location])
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/home/LogoPink.png" }) {
        childImageSharp {
          fluid(maxWidth: 375) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      {!hideHeader && (
        <div className={styles.paperContainer}>
          <Paper width="auto">
            <div className={styles.container}>
              <div>
                <Link to="/" aria-label="Go to the home page.">
                  <Img
                    fluid={data.file.childImageSharp.fluid}
                    style={{ width: "305px", objectFit: "cover" }}
                  />
                </Link>
              </div>
              <Hidden smDown key="fullNav">
                <div className={styles.linkFlexContainer}>
                  <ListLink to="/shop">Shop</ListLink>
                  <ListLink to="/about">About</ListLink>
                  <ListLink to="/contact">Contact</ListLink>
                  <ListLink to="/social">Social</ListLink>
                  <div className={SnipcartStyles}>
                    <ListLink
                      useHref={true}
                      icon={MdShoppingCart}
                      style={{ cursor: "pointer" }}
                      className="snipcart-checkout"
                    >
                      Cart
                    </ListLink>
                  </div>
                </div>
              </Hidden>
              <Hidden mdUp key="menuNav">
                <div
                  onClick={() => {
                    setDrawer(true)
                  }}
                  style={{ paddingLeft: "15px", display: "inline-block" }}
                >
                  <FaBars />
                </div>
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={() => {
                    setDrawer(false)
                  }}
                >
                  <ListLink
                    style={{ marginTop: "13px", marginBottom: "13px" }}
                    to="/shop"
                    icon={MdShoppingBasket}
                  >
                    Shop
                  </ListLink>
                  <Divider />
                  <ListLink
                    style={{ marginTop: "13px", marginBottom: "13px" }}
                    to="/about"
                    icon={MdInfo}
                  >
                    About
                  </ListLink>
                  <Divider />
                  <ListLink
                    style={{ marginTop: "13px", marginBottom: "13px" }}
                    to="/contact"
                    icon={MdEmail}
                  >
                    Contact
                  </ListLink>
                  <Divider />
                  <ListLink
                    useHref={true}
                    icon={MdShoppingCart}
                    style={{ marginTop: "13px", marginBottom: "13px" }}
                    className="snipcart-checkout"
                  >
                    Cart
                  </ListLink>
                  <Divider />
                  <ListLink
                    style={{ marginTop: "13px", marginBottom: "13px" }}
                    useHref={true}
                    href="https://www.twitter.com"
                    icon={FaTwitter}
                  >
                    Follow us on Twitter
                  </ListLink>
                  <Divider />
                  <ListLink
                    style={{ marginTop: "13px", marginBottom: "13px" }}
                    useHref={true}
                    href="https://www.facebook.com"
                    icon={FaFacebookSquare}
                  >
                    Follow us on Facebook
                  </ListLink>
                  <Divider />
                  <ListLink
                    style={{ marginTop: "13px", marginBottom: "13px" }}
                    useHref={true}
                    href="https://www.instagram.com"
                    icon={FaInstagram}
                  >
                    Follow us on Instagram
                  </ListLink>
                </Drawer>
              </Hidden>
            </div>
          </Paper>
        </div>
      )}
    </>
  )
}
