import React from 'react'
import Header from './Header/Header';
import styles from './Layout.module.css';
import { Helmet } from "react-helmet"



export default function Layout(props) {
    return(
        <div>
            <Helmet>
                <meta name="p:domain_verify" content="f8db7104883159ed010b9cc774f76181"/>
            </Helmet>
            <Header/>
            {
                props.centered === true ?
                <div className={styles.contentContainerCenter} style={props.style}>
                    {props.children}
                </div>
                :
                <div className={styles.contentContainer} style={props.style}>
                    {props.children}
                </div>
            }
        </div>
    )
}
