import React from 'react';
import { Link } from 'gatsby';
import styles from './Header.module.css';
import classNames from 'classnames';

export default function ListLink(props) {
    const { to, icon, children, useHref, href, style, className } = props;
    const anchorClasses = classNames(styles.linkContainer, className);
    const childCount = React.Children.count(children);
    if (useHref === true){
        return(
            <a href={href} target='_blank' rel="noopener noreferrer" className={anchorClasses} style={ childCount === 0 ? {padding: '7px 0px 7px 7px', ...style} : {...style}}>
                {
                    icon ?
                    <props.icon style={{fontSize:'20px', color: '#000000'}}/>
                    :
                    <></>
                }                
                <h3 className={styles.ListLink}>{children}</h3>
            </a>
        )
    }
    return(
        <Link to={to} className={styles.linkContainer} style={style} activeClassName={styles.activeLink}>
            {
                icon ?
                <props.icon style={{fontSize:'20px', color: '#000000'}}/>
                :
                <></>
            }                
            <h3 className={styles.ListLink}>{children}</h3>
        </Link>
    )
}