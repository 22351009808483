import React from "react";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper/Paper.js";
import classnames from "classnames";
const useStyles = makeStyles({
	root: {
		width: "100vw",
		height: "80px",
		margin: 0,
		padding: 0
	}
});

export default function PaperComponent(props) {
	const classes = useStyles();
	const { className } = props;
	const allClasses = classnames(classes.root, className);
	return (
		<Paper
			className={allClasses}
			style={{
				width: props.width,
				height: props.height,
				backgroundColor: props.bgColor || "#FFFFFF",
				boxShadow: "3px 3px 3px 3px #00000026",
				...props
			}}
		>
			{props.children}
		</Paper>
	);
}
